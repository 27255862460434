import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../../AppStyle";

export const LinkPage = styled(Link)`
  text-decoration: none !important;
  @media screen and (max-width: 960px) {
    margin-bottom: 0;
  }
`;

export const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 140vh;
  overflow: hidden;
  background-size: cover;
  margin-top: 100px;
  box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding-top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerWrapper = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  background: rgba(0, 0, 0, 0.3);
  padding: 30px 30px 0px 30px;
  border-radius: 8px; 
  line-height: 1.6;
  height: 400px;
`;

export const Container = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  padding: 0px 30px 0px 30px;
  @media screen and (max-width: 1200px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0px 0px 0px;
  }
`;

export const MainHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  .titleMainHeading {
    font-size: 60px;
    color: #dddf21;
    font-weight: bold;
    padding-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }
  .trademark {
    font-size: 20px;
  }
  .DescriptionMainHeading {
    font-size: 20px;
    line-height: 1.6;
    color: #fff;
    padding-bottom: 32px;
    font-family: "Montserrat", sans-serif;
  }
  @media screen and (max-width: 1200px) {
    display: flex;
    .titleMainHeading {
      font-size: 25px;
      color: #dddf21;
      text-align: center;
    }
    .subliTitleMainHeadin {
      font-size: 30px;
      color: #fff;
      text-align: center;
    }
    .DescriptionMainHeading {
      font-size: 18px;
      color: #fff;
      text-align: center;
    }
  }
`;

export const HeroButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 4rem;
  color: black;
  border: 0px;
  border-radius: 15px;
  margin-bottom: 80px;
  background: #dddf21;
  margin-top: 100px;

  &:before {
    height: 500%;
  }

  &:hover:before {
    height: 0%;
  }

  &:hover {
    color: #666;
    background-color: black;
    transition-duration: 0.5s;
  }

  @media screen and (max-width: 1200px) {
    width: 200px;
    height: 3rem;
    margin: 0;
  }
`;

export const HeroBackground = styled.div`
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: -1;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media screen and (max-width: 1200px) {
    height: 60rem;
  }
`;

export const HeroText = styled.div`
  background: white;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const MainIllustration = styled.img`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
  height: auto;
  color: black;
  border: 0px;
  border-radius: 15px;
  padding: 0px;

  @media screen and (max-width: 1200px) {
    width: 300px;
    height: auto;

    P {
      font-size: 20px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
`;

export const ItemWrapper = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  gap: 5.9rem;
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 10px 0px 0px 0px;
  }
`;

export const HeroFeatures = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin-top: 20px;
  height: auto;
  margin-bottom: 80px;
  font-size: 16px;
  color: #fff;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Montserrat", sans-serif;
  text-align: center;
`;

export const FeatureImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: block;
  margin: auto;
`;
