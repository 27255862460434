import React from "react";
import AuthAsAService from "../components/AuthAsAService";

const Home = () => {
  return (
    <>
      <AuthAsAService />
    </>
  );
};

export default Home;
