import { HeroBackground } from "../AuthAsAService/styles";
import styled from "styled-components";

const VideoBackground = () => (
    <HeroBackground>
      <video autoPlay loop muted>
        <source src="https://primatio.firebaseapp.com/assets/primatio-compressed.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Overlay />
    </HeroBackground>
  );

  const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark gray overlay with 50% opacity */
  z-index: 1; /* Ensure the overlay is above the video */
`;

  
  export default VideoBackground;
  