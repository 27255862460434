import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
  {
    name: "Instagram",
    icon: iconStyle(FaInstagram),
    link: "https://instagram.com/primatiohq",
  },
  {
    name: "LinkedIn",
    icon: iconStyle(FaLinkedin),
    link: "https://www.linkedin.com/company/primatiohq",
  },
];

export const footerData = [
  {
    title: "Principal",
    links: [
      { title: "Blog", link: "https://medium.com/@primatio" },
    ],
  },
  // {
  //   title: "Serviços",
  //   links: [
  //     { title: "CISO-as-a-Service", link: "/ciso-as-a-service" },
  //     {
  //       title: "Login sem senha",
  //       link: "/autenticacao-como-servico",
  //     },
  //   ],
  // },
  {
    title: "",
    links: [{ title: "", link: "" }],
  },
];
