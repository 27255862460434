import React from "react";

import {
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLogo,
  SocialIcon,
  FooterRights,
  FooterSocialIcon,
  FooterWrapper,
  FooterColumn,
  FooterGrid,
  FooterRowSocial,
  FooterAddress,
} from "./styles";
import { footerData, footerSocialData } from "../../data/FooterData";
import { Section } from "../../AppStyle";

function Footer() {
  return (
    <Section padding="4rem 0 2rem 0">
      <FooterWrapper>
        <FooterRowSocial align="center" margin="auto  0 0 0" gap="1rem">
          <FooterLogo to="/">
            <SocialIcon src="./assets/logo.png" />
          </FooterLogo>
          <div>
            {footerSocialData.map((social, index) => (
              <FooterSocialIcon
                key={index}
                href={social.link}
                target="_blank"
                aria-label={social.name}
              >
                {social.icon}
              </FooterSocialIcon>
            ))}
          </div>
        </FooterRowSocial>
        <FooterGrid justify="space-between">
          {footerData.map((footerItem, index) => (
            <FooterLinkItems key={index}>
              <FooterLinkTitle>{footerItem.title}</FooterLinkTitle>
              {footerItem.links.map((item, linkIndex) => (
                <FooterLink key={linkIndex} href={item.link}>
                  {item.title}
                </FooterLink>
              ))}
            </FooterLinkItems>
          ))}
          {
            <FooterColumn id="footerLogo">
              <FooterAddress>
              Rua Do Brum, 248 - Cxpst 35, Recife, Pernambuco, 50030-260
              </FooterAddress>
              <FooterAddress>
                Rua Tomás Ribeiro, n° 41, 1° esquerdo, Lisboa, Código Postal:
                1050-225 (Responsável: Dra. Alyne Andrade)
              </FooterAddress>
            </FooterColumn>
          }
        </FooterGrid>
        <FooterRights>
          Primatio Cyber Security ltda. CNPJ: 44.910.138-0001/01
        </FooterRights>
      </FooterWrapper>
    </Section>
  );
}

export default Footer;
