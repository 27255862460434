import React from "react";

import {
  LinkPage,
  HeroBackground,
  MainHeading,
  MainIllustration,
  Container,
  HeroSection,
  ItemWrapper,
  HeroFeatures,
  FeatureImage,
  ContainerWrapper,
  HeroButton,
} from "./styles";

import VideoBackground from "../VideoBackground/VideoBackground";

export default function Hero() {
  return (
    <HeroSection>
      <VideoBackground />

      <HeroBackground />

      <Container>
        <MainHeading>
          <div className="titleMainHeading">Primatio<sup className="trademark">®</sup></div>
          <div className="DescriptionMainHeading">
            As pessoas odeiam senhas.
          </div>
          <div className="DescriptionMainHeading">
          Fornecemos uma solução de autenticação totalmente revolucionária, 
          permitindo uma experiência <b>PREMIUM</b> para que você tenha certeza de quem 
          realmente está usando o seu aplicativo ou sistema. 
          </div>
          <div className="DescriptionMainHeading">
          Vamos além da simples 
          autenticação por face ou voz; entregamos uma prova de vida, assegurando 
          acesso seguro a todas as funções vitais do seu sistema.
          </div>
        </MainHeading>
        <LinkPage to="/">
          <MainIllustration src="./assets/auth-main.png"></MainIllustration>
        </LinkPage>
      </Container>
      
      <ItemWrapper>
        <ContainerWrapper>
          <FeatureImage src="./assets/auth-step1.png" />
          <HeroFeatures>
            Acesso seguro para qualquer usuário e dispositivo, em qualquer
            ambiente, de qualquer lugar.
          </HeroFeatures>
        </ContainerWrapper>

        <ContainerWrapper>
          <FeatureImage src="./assets/auth-step2.png" />
          <HeroFeatures>
            Obtenha a tranquilidade que apenas a visibilidade e a confiança
            completas da prova de vida pode trazer.
          </HeroFeatures>
        </ContainerWrapper>
        <ContainerWrapper>
          <FeatureImage src="./assets/auth-step3.png" />
          <HeroFeatures>
            Responda mais rapidamente às ameaças com uma solução SaaS escalável
            e fácil de implantar que protege nativamente todos os aplicativos.
          </HeroFeatures>
        </ContainerWrapper>
      </ItemWrapper>
      <ItemWrapper>
      <HeroButton
          onClick={() => (window.location.href = "mailto:felipe@primat.io")}
        >
          Fale conosco
        </HeroButton>
      </ItemWrapper>
    </HeroSection>
  );
}
